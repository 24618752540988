import styled from "styled-components";
import { BaseContainer } from "../../BaseContainer/styles";

// import { colors } from '../../styles/GlobalStyles.js'

export const SectionTitle = styled.h4`
  font-size: 15px;
  background-color: #eae5e5;
  padding: 3px 6px;
`;

export const DatePicker = styled.div`
  margin: 10px 0px;
`;

export const TicketLabel = styled.div`
  font-weight: bold;
  color: #222;
`;

const percentA = 60;

export const Left = styled.div`
  float: left;
  padding: 10px 0px;
  width: ${percentA}%;
  // border: 1px solid #0f0;
`;

export const price = styled.div`
  font-size: 0.8em;
`;

export const Right = styled.div`
  float: left;
  padding: 10px 0px;
  width: ${100 - percentA}%;
  text-align: right;
`;

export const clear = styled.div`
  clear: both;
`;

export const TicketSelectionContainer = styled(BaseContainer)`
  padding: 0px;
`;

export const Label = styled.label`
  margin-bottom: 0px; // override bootstrap
`;

export const Warning = styled.div`
  color: #c00;
`;

export const InputWarningMessage = styled.div`
  color: #c00;
`