import React from "react";
import { InputWarningMessage } from './styles'

// display the drowdown for an individual ticket type.
const TicketCountSelect = ({ name, allTicketsDisabled, seatsRemaining, curValue, onChange }) => {
    // create an array of <option> elements (for the current <select>)
    var options = [];
    var disabled = allTicketsDisabled
    var disabledStatus = allTicketsDisabled
    var labelSuffix = ""
    var warningMsg = ''

    // the number of select options to render
    // (UI note: someone may select 5 tickets, change the date and that date (for instance only) has "3 tickets remaining".
    // -- in this case, preserve their select choice by rendering the 5 tickets, but show tickets 4 & 5 as "sold out".
    // -- i.e. don't change their choice to 3 without their knowledge!)
    var noOfOptionsToRender = (curValue > seatsRemaining) ?
        curValue :
        seatsRemaining

    if (noOfOptionsToRender < 10) {
        // always show up to 10 tickets, but some of them may show "sold out!" on the end.
        noOfOptionsToRender = 10
    }
    
    for (var i = 0; i <= noOfOptionsToRender; i++) {
        if (seatsRemaining < i) {
            if (i == 1) {
                warningMsg = 
                    <InputWarningMessage>
                        Sold out!!
                    </InputWarningMessage>
            }

            disabledStatus = true
            labelSuffix = " - sold out!"
        } else {
            // labelSuffix = ' tickets'
        }
        options.push(
            <option disabled={disabledStatus} value={i}>
                {i + labelSuffix}
            </option>
        );
    }

    // create an error notice if the selected <option> is sold out
    // console.log(`Comparing: seatsRemaining: ${seatsRemaining} to selected quantity: ${curValue} (#wef2we)`);
    // console.log(`select: "${name}" = ${curValue}. Length = ${options.length} (#sw3223f)`);
    // console.log('options (#435sdf): ', options)

    // var greyedClass = "";
    if (disabled === true) {
        // todo: implement this:
        // greyedClass = "greyed";
    } else {
        // console.log("disabled off #wc23exqw");
    }

    return (
        <>
            <select
                style={{'width': '120px' }}
                
                disabled={disabled}
                // value={this.setTicketSelectionValue}
                value={curValue}
                onChange={onChange}
                name={name}
            >
                {options}
            </select>
            {warningMsg}
        </>
    )
}

export default TicketCountSelect