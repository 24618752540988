import React from "react";

// componenents
import Layout from "../components/Layout";
// import { TicketSelectionForm } from "./../components/forms/TicketSelectionForm/index";
import { propSets, ComponentWrapper } from "./../components/forms/TicketSelectionForm/testing/data"
import { TicketSelectionForm } from "./../components/forms/TicketSelectionForm/index";

// this page is used for developing new components, in particular, those that will be added to storybook
const IndexPage = () => {
    console.log('xyz')
    return (
        <Layout>
            <h2>Component testing page</h2>
            use this page to develop and test components before adding them to story book.

            {/* <ComponentWrapper {...propSets.singleAvailableDate} /> */}
            <TicketSelectionForm {...propSets.singleAvailableDate} />
        </Layout>
    )
};

export default IndexPage;