const axios = require("axios");

const getRemainingTickets = async (sessionId) => {
  // alert("fetch remaining tickets via getRemainingTickets()");

  const response = await axios
    .get(
      "https://mind456vec.execute-api.ap-southeast-2.amazonaws.com/dev/getReminaingTicketsDummy"
    )
    .then((response) => {
      // this.setState({report:response.data});
      console.log("axios response received: (#23rfcw33): ", response.data);

      if (response.data.error) {
        // todo: turn this into a html text alert
        alert(
          "There has been an error while requesting the remaining tickets for this tour. Please refresh and try again."
        );
        console.log("error message: (#wgw4vsfdv): ", response.data.error);
      }
      // alert(response.data.error);
      return response.data;
    }).catch((error) => console.log("(#23d2wec2): ", error));

  console.log("result of getRemainingTickets() (#sdcasfaerfw): ", response);

  return response;
};

export default getRemainingTickets;

export const testData = {
  1: {
    label: "Early bird / first release",
    id: 923158,
    price: 49,
    capacity: 12,
    seatsUsed: 2,
    seatsRemaining: 10,
  },
  2: {
    label: "Second release",
    id: 923160,
    price: 53,
    capacity: 24,
    seatsUsed: 19,
    seatsRemaining: 5,
  },
  3: {
    label: "Third release",
    id: 923159,
    price: 57,
    capacity: 20,
    seatsUsed: 12,
    seatsRemaining: 8,
  },
};
