import React from "react";

import { BaseContainer } from "../BaseContainer/styles";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// this is a generic Container with 2 columns - used to reduce boilerplate and mess in components
const TwoColumnContainer = ({ contents, leftSml, leftMd, leftLrg, leftStyle, config }) => {

    return (
        <BaseContainer>
            {contents.map(curItem => {
                if (curItem.full) {
                    return (
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                {curItem.full}
                            </Col>
                        </Row>
                    )
                } else {
                    
                    if (abandonRowRender(curItem, config)) {
                        return null
                    }
                    return (
                        <Row>
                            <Col xs={leftSml} md={leftMd} lg={leftLrg} style={leftStyle}>
                                {curItem.left}
                            </Col>
                            <Col xs={12-leftSml} md={12-leftMd} lg={12-leftLrg}>
                                {curItem.right}
                            </Col>
                        </Row>
                    )
                }
                
            })}
        </BaseContainer>
    )
}

// It inspects config and compares it to the cur row. Returns true if the row should not be rendered.
const abandonRowRender = (curItem, config) => {

    if (!config) { return false }                   // no config exists
    const abandonRender = config.abandonRender 

    if (!abandonRender) { return false }     // no config exists

    if (abandonRender.whenLeftColEmpty && !curItem.left) {
        return true
    }   
    if (abandonRender.whenRightColEmpty && !curItem.right) {
        return true
    }

    return false
}

export default TwoColumnContainer