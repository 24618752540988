import React from "react";

import { Label } from "./styles";
import * as styles from "./styles";

import TicketCountSelect from './TicketCountSelect'
import { getTicketTypeSelectName } from './funcs'

// Renders selects for each ticket type and allows users to choose a quantity to purchase.
// marker outputTicketSelects()
const TicketTypeSelects = ({ ticketTypes, ticketTypeQuantity, setTicketTypeQuantity, disableAll, clearWarningMsg, checkQuantitySelectionIsValid }) => {
    console.log("TicketTypes (#4sdvf): ", ticketTypes);
    console.log("TicketTypes (#4sdvf): ", ticketTypeQuantity);
    return Object.entries(ticketTypes).map((t, k) => {
        const curTicketType = t[1];
        console.log("ticketType (#534r23dx): ", curTicketType);

        const selectName = getTicketTypeSelectName(curTicketType.id);
        var curValue = ticketTypeQuantity[curTicketType.id];
        console.log(`ticket type id: '${curTicketType.id}', quantity (#sdf32ctg): ${curValue}`);

        // console.log("state (#vsv4eccwe): ", this.state);

        // output a <select> (and it's options) for the current ticket type:
        return (
            <div>
            <styles.Left>
                <Label class="default">
                <styles.TicketLabel>{curTicketType.label}</styles.TicketLabel>
                <styles.price>${curTicketType.price} each</styles.price>
                </Label>
            </styles.Left>
            <styles.Right>
                <TicketCountSelect
                    name={selectName}
                    seatsRemaining={curTicketType.seatsRemaining}
                    allTicketsDisabled={disableAll}
                    curValue={curValue}
                    onChange={(e) => handleTicketSelectionChange(e, setTicketTypeQuantity, clearWarningMsg, checkQuantitySelectionIsValid)}
                />
            </styles.Right>
            <styles.clear />
            </div>
        );
    });
}

export default TicketTypeSelects

// when the user makes a change to a ticket quantity (of any ticket type <select>)
const handleTicketSelectionChange = (event, setTicketTypeQuantity) => {
    // console.log("#sz3wc: ", event.target);
    const selectName = event.target.name      // something like: "ticketSelect_3957"
    const newValue = parseInt(event.target.value)

    const ticketTypeId = getTicketTypeId(selectName)

    // console.log("Change to TicketSelection <select> (#sf32wc):", ticketTypeId)
    
    setTicketTypeQuantity(ticketTypeId, newValue)

    // this.setState({ [ticketTypeId]: newValue });
}

// return '4536' from the string: 'selectName_4536' (for instance)
const getTicketTypeId = (selectName) => {
    // var s = '/Controller/Action';
    var n = selectName.indexOf('_');
    const id = parseInt(selectName.substring(n+1, selectName.length));
    // const id = selectName.substring(0, n != -1 ? n : selectName.length);
    console.log('selectName: "' + selectName + '", id is determined to be (#ads4): "' + id + '"')

    return id
    
}