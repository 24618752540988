import React, { useState } from "react";

import TwoColumnContainer from "../../TwoColumnContainer"

const PickupLocationsDropdown = ({ locations, selectedPickupLocation, setSelectedPickupLocation }) => {
    // const [ selectedPickupLocation, setSelectedLocationItem ] = useState('none-selected');
    console.log('selectedPickupLocation  #9szs: ', selectedPickupLocation)

    console.log('pickup locations (#45esdf): ', locations)

    if (!locations) {
        throw new Error ('the pickupLocations array was empty.')
    }

    if (locations.length == 1) {
        return (
            <>
                {/* There's 1 pickup location available: */}
                <PickupLocation location={locations[0]} shortenedDetails={false} />
            </>
        )
    }

    // return a location object from the locations array (or return "not selected" values if index=false)
    const getLocationData = (index) => {
        console.log('index (#54gdg): ', index)
        console.log('locations (#54gdg): ', locations)
        if (index === 'none-selected') {
            return {
                locationName:   'please select below...',
                address:        'please select below...',
                pickupTime:     'please select below...'
            }
        }

        console.log('Selected location (#09ff): ', locations[index])
        return locations[index]
        // return {
        //     locationName:   locations[index].locationName,
        //     address:        locations[index].address,
        //     pickupTime:     locations[index].pickupTime

        // }
    }

    const optionsArr = locations.map(
        (curLocation, k) => {
            console.log('curLocation (#dvs33dr): ', curLocation)
            const locationId = curLocation.id
            if (!locationId) {
                throw new Error ('No id set for select option. please fix this .')
            }
        //   const index = t[0];
        //   const element = t[1];
  
        //   const curDate = new Date(element.startTimeLocal);
  
          // const caption = curDate.toDateString() + " ";
          // for more on dateFormat: https://stackoverflow.com/questions/3552461/how-to-format-a-javascript-date
        //   // note: h:MMTT removed, as there may be multiple pickup locations (that each have their own pickup times).
        //   const caption = `${curLocation.locationName} - ${curLocation.address} [${curLocation.pickupTime}]`
          const caption = `[${curLocation.pickupTime}] ${curLocation.address}`
  
        //   const sessionId = element.id;
        //   const disabled = this.isDateInThePast(element.startTimeLocal);
  
            return (
            // <option disabled={disabled} value={sessionId}>
            // </option>
                <option value={k}>
                    {caption}
                </option>
            )
        }
    );

    // display the pickup information in the container.
    const handleOptionSelected = (e) => {
        const arrIndex = e.target.value
        console.log('optionSelected handler #234sdf: ', arrIndex)
        setSelectedPickupLocation(arrIndex)
    }
  
    return (
        <>
            Please choose your pickup location:
            <PickupLocation location={getLocationData(selectedPickupLocation)} shortenedDetails={true}  />
            <select
            //   value={'selectedPickupLocation'}
              onChange={handleOptionSelected}
              name={'selectedPickupLocation'}
            >
                <option value="none-selected">
                    Please select one...
                </option>
                {optionsArr}
            </select>
        </>
    );
}

export default PickupLocationsDropdown

// display a 2 column container with pickup location information
const PickupLocation = ({ location, shortenedDetails }) => {
    console.log('location (#34sdv): ', location)
    
    var contents = []
    // shortenedDetails = false is used when there's no dropdown (i.e. when there's only a single pickup location)
    if (shortenedDetails) {
        contents = [
            {
                left: `place:`,
                right: location.locationName
            },
            {
                left: `notes:`,
                right: location.notes
            }
        ]
    } else {
        contents = [
            {
                full: `There's 1 pickup location available:`
            },
            {
                left: `place:`,
                right: location.locationName
            },
            {
                left: `address:`,
                right: location.address
            },
            {
                left: `time:`,
                right: location.pickupTime
            },
            {
                left: `notes:`,
                right: location.notes
            }
        ]
    }

    const leftStyle = {'font-weight': 'bold'}
    const leftColSize = 3
    const config = {
        abandonRender: {
            whenLeftColEmpty: false,
            whenRightColEmpty: true
        }
    }

    return (
        <TwoColumnContainer contents={contents} leftSml={leftColSize} leftMd={leftColSize} leftLrg={leftColSize} leftStyle={leftStyle} config={config} />
    )
}