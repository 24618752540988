import dateFormat from "dateformat";

// add an OTA product to the CartItems array - to appear in the /checkout page.
const addOtaProductToCart = (product, ticketTypeId, ticketTypeLabel, ticketTypePrice, quantity, sessionId, sessionDateTime, selectedPickupLocation, addCartItem ) => {
    const pickupLocationString = generatePickupLocationString(selectedPickupLocation)
    const pickupLocationId = selectedPickupLocation.id

    console.log('selectedPickupLocation (#zeciu2): ', selectedPickupLocation)
    if (quantity == 0) {
      return null
    }
    console.log('#avz1 product: ', product)
    // [OTA marker] [contentful product entry ID] [session_id] [pickupLocation] [ticketTypeId]
    const reference = "OTA:" + product.contentful_id + ':' + sessionId + ':' + pickupLocationId + ':' + ticketTypeId
    // example: 'OTA:CVS3GFSDF4454ERGVD:0392:234849:45832',
    console.log('#avz2 reference: ', reference)
  
    console.log ('new OTA cartItem "reference" (#zxa6): ', reference)
    console.log ('sessionDateTime (#zxa6): ', sessionDateTime)
    console.log ('product (#zxa6): ', product)

    const curDate = new Date (sessionDateTime)
    // const dateTimePart = curDate.toDateString()
    // const dateTimePart = curDate.toString('dd mm')
    console.log('sessionDateTime (#zxa6): ', curDate)
    const dateTimePart = dateFormat(curDate, "d/mmmm/yyyy - dddd");

    // const pickupLocationAsString = 'Pickup'

    addCartItem (
        product.name,
        ["Date: " + dateTimePart, pickupLocationString],
        quantity,
        ticketTypePrice,
        reference,     // [OTA marker] [contentful product entry ID] [session_id] [pickupLocation] [ticketTypeId]
        'http://empty-todo',             // url to product
        {
            // 'displayGroup': 'OTA:34GERFKASI432KOZ4234:0392:234849',
            'variantLabel': ticketTypeLabel
        },
        {
            disableQuantityChanger: true
        }
    )
}

const generatePickupLocationString = (pickupLocation) => {
    const pickupLocationName = pickupLocation.locationName
    const pickupAddress = pickupLocation.address
    const pickupTime = pickupLocation.pickupTime
    const pickupLocationString = "Pickup location: " + pickupLocationName + ', ' + pickupAddress + ' (at: ' + pickupTime + ')'

    return pickupLocationString
}

export default addOtaProductToCart