// information to be displayed while loading acctual ticket data from server
export const getTicketLoadingData = () => {
  const ticketTypes = {
    1: {
      label: "Loading Data...",
      id: 923158,
      seatsRemaining: 5,
      price: 0,
    },
    // 1: {
    //   label: "Early bird / first release",
    //   id: 923158,
    //   seatsRemaining: 25,
    //   price: 49,
    // }
    // 2: {
    //   label: "Second release",
    //   id: 923160,
    //   seatsRemaining: 5,
    //   price: 53,
    // },
    // 3: {
    //   label: "Third release",
    //   id: 923159,
    //   seatsRemaining: 24,
    //   price: 57,
    // },
  };

  return ticketTypes;
};
